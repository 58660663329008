.navbar.top {
  border-bottom: 0.1rem solid #00983a;
  margin-bottom: 0.5rem;
}

#top-logo {
  max-height: 69px;
  max-width: 100%;
}

.navbar.bottom {
  border-top: 0.1rem solid #00983a;
}

.scrollUp {
  position: fixed;
  right: -100px;
  bottom: 150px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: #00983a;
  font-size: 35px;
  padding: 0px;
  border-radius: 4px;
  z-index: 1000;
}

.transitionUp {
  right: 20px;
}